import { useQuery } from '@apollo/client';
import { AccountBalance } from '@mui/icons-material';
import { Badge, Drawer, Grid, IconButton, MenuItem, TextField, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { GET_USER } from '../../graphql';
import { NewBankAccountForm } from '../forms';

const SelectBankAccount = ({
  bankId,
  setBankId,
  customCompany,
  hideText,
  onAccountCreate,
  customBankAccounts,
  refetchQueriesOnCreation,
  textFieldProps,
  typographyProps,
  ...props }) => {
  const { data } = useQuery(GET_USER, { skip: customCompany });
  const [openForm, setOpenForm] = useState();
  const company = customCompany || data?.getUser.selectedCompany;
  const bankAccounts = (customBankAccounts
    || data?.getUser.selectedCompany?.bankAccounts?.filter((a) => a?.active));
  const handleAccountCreate = (newAccount) => {
    onAccountCreate(newAccount);
    setBankId(newAccount.id);
    setOpenForm(false);
  };
  return (
    <Grid
      container
      rowSpacing={1}
      columnSpacing={1}
    >
      {!hideText && (
      <Grid item xs={12}>
        <Typography {...typographyProps}>
          Seleccione la cuenta del banco para realizar el depósito:
        </Typography>
      </Grid>
      )}
      <Grid item xs={9}>
        <TextField
          select
          label='Selecciona una cuenta'
          variant='filled'
          value={bankId}
          onChange={({ target }) => setBankId(target.value)}
          disabled={!bankAccounts.length}
          SelectProps={{
            native: false,
          }}
          fullWidth
          sx={{
            '& .MuiSvgIcon-root': { color: 'primary.main' },
          }}
          {...textFieldProps}
        >
          {bankAccounts.filter((bank) => bank.active).map((bank) => (
            <MenuItem
              key={bank.id}
              value={bank.id}
            >
              {`${bank.accountType} ${bank.bankName} N°${bank.accountNumber}`}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={3}>
        <Tooltip title="Agregar cuenta">
          <IconButton
            value="add"
            size="small"
            sx={{ mt: 1 }}
            onClick={() => { setOpenForm(true); }}
          >
            <Badge badgeContent="+" color="primary" sx={{ '& .MuiBadge-badge': { fontSize: 25 } }}>
              <AccountBalance color="primary" />
            </Badge>
          </IconButton>
        </Tooltip>
      </Grid>
      <Drawer
        open={openForm}
        onClose={() => { setOpenForm(false); }}
        anchor="right"
        PaperProps={{
          sx: { width: { xs: '100%', md: '50%', lg: '25%' } },
        }}
        sx={{ zIndex: 1350, position: 'absolute' }}
      >
        <NewBankAccountForm
          cancel={() => { setOpenForm(false); }}
          accounts={bankAccounts}
          customCompany={company}
          onAccountCreate={handleAccountCreate}
          sx={{ padding: 2 }}
          refetchQueriesOnCreation={refetchQueriesOnCreation}
        />
      </Drawer>
    </Grid>
  );
};

SelectBankAccount.propTypes = {
  setBankId: PropTypes.func.isRequired,
  bankId: PropTypes.string,
  customCompany: PropTypes.shape({
    id: PropTypes.string,
    rut: PropTypes.number,
    name: PropTypes.string,
  }),
  customBankAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      bankName: PropTypes.string,
      accountNumber: PropTypes.string,
    }),
  ),
  hideText: PropTypes.bool,
  onAccountCreate: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  refetchQueriesOnCreation: PropTypes.array,
  formControlProps: PropTypes.object,
  typographyProps: PropTypes.object,
};

SelectBankAccount.defaultProps = {
  onAccountCreate: () => { },
  bankId: '',
  customBankAccounts: null,
  customCompany: null,
  hideText: false,
  refetchQueriesOnCreation: [GET_USER],
  textFieldProps: {},
  typographyProps: { variant: 'h2'},
};

export default SelectBankAccount;
