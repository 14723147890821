import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Checkbox, Collapse, IconButton, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useBooleanState } from '../../hooks';
import FingoTableCell from './FingoTableCell';

const FingoTableRow = ({
  columns,
  row,
  checkboxSelection,
  isRowSelectable,
  selectionModel,
  onSelectionModelChange,
  collapsible,
  isRowCollapsible,
  collapseComponent: CollapseComponent,
}) => {
  const [open, toggleOpen] = useBooleanState(false);

  const selectClickHandler = (event, id) => {
    const isChecked = !!event.target?.checked;
    if (isChecked) {
      onSelectionModelChange([...selectionModel, id]);
    } else {
      onSelectionModelChange(selectionModel.filter((rowId) => rowId !== id));
    }
  };

  const collapsibleRow = collapsible && isRowCollapsible({ row });
  const selectableRow = checkboxSelection && isRowSelectable({ row });
  return (
    <>
      <TableRow hover role="checkbox" tabIndex={-1}>
        {(collapsible || checkboxSelection) && (
          <TableCell padding="checkbox" sx={{ minWidth: collapsibleRow && selectableRow ? '70px' : null }}>
            {selectableRow && (
              <Checkbox
                color="primary"
                checked={selectionModel.includes(row.id)}
                indeterminate={!isRowSelectable({ row })}
                disabled={!isRowSelectable({ row })}
                onChange={(event) => selectClickHandler(event, row.id)}
              />
            )}
            {collapsibleRow && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => {
                  toggleOpen();
                }}
              >
                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            )}
          </TableCell>
        )}
        {columns.map(({ type, field, renderCell, valueGetter, width }) => (
          <FingoTableCell
            key={`${row.id}-${field}`}
            type={type}
            row={row}
            renderCell={renderCell}
            valueGetter={valueGetter}
            width={width}
          />
        ))}
      </TableRow>
      {collapsibleRow && open && (
        <TableRow hover role="checkbox" tabIndex={-1}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length + 1}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <CollapseComponent row={row} />
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

FingoTableRow.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      headerName: PropTypes.string,
      sortable: PropTypes.bool,
      renderCell: PropTypes.func,
      valueGetter: PropTypes.func,
    }),
  ).isRequired,
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  checkboxSelection: PropTypes.bool.isRequired,
  isRowSelectable: PropTypes.func.isRequired,
  selectionModel: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelectionModelChange: PropTypes.func.isRequired,
  collapsible: PropTypes.bool.isRequired,
  isRowCollapsible: PropTypes.func.isRequired,
  collapseComponent: PropTypes.func.isRequired,
};

export default FingoTableRow;
