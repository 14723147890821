import React from 'react';
import PropTypes from 'prop-types';
import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableSortLabel,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

const Header = (props) => {
  const classes = useStyles();
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    selectableRows,
    onRequestSort,
    headCells,
    withSelect } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {withSelect && (
          <TableCell padding="none">
            <Checkbox
              disabled={selectableRows <= 0}
              indeterminate={selectableRows <= 0
                || (numSelected > 0 && numSelected < selectableRows)}
              checked={(selectableRows > 0 && numSelected === selectableRows)}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all invoices' }}
              color="primary"
            />
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              disabled={headCell.disableOrderBy}
            >
              <Typography variant="body1">{headCell.label}</Typography>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

Header.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string,
  selectableRows: PropTypes.number.isRequired,
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      extraDataKey: PropTypes.string,
      extraDataKey2: PropTypes.string,
      isSearchable: PropTypes.bool,
      align: PropTypes.string,
      size: PropTypes.number,
      disableOrderBy: PropTypes.bool,
      disablePadding: PropTypes.bool,
    }),
  ).isRequired,
  withSelect: PropTypes.bool,
};

Header.defaultProps = {
  withSelect: false,
  orderBy: '',
};

export default Header;
