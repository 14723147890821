import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';
import { GoBackButton } from '../buttons';

const HeaderStack = ({ showGoBack, title, subtitle, children }) => (
  <Stack direction="row" alignItems="center">
    {showGoBack && (
    <GoBackButton />
    )}
    <Box>
      <Typography variant="h1" color="primary.main">
        {title}
      </Typography>
      {subtitle && (
      <Typography variant="headerSubtitle">{subtitle}</Typography>
      )}
    </Box>
    {children}
  </Stack>
);

HeaderStack.propTypes = {
  showGoBack: PropTypes.bool,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.node.isRequired,
};

HeaderStack.defaultProps = {
  showGoBack: false,
  subtitle: null,
};

export default HeaderStack;
