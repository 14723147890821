import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';

const FingoStack = ({ children, sx, ...props }) => (
  <Stack
    alignItems="center"
    width="100%"
    borderRadius={2}
    py={1}
    ml={0}
    spacing={1}
    direction="row"
    bgcolor="background.light"
    sx={{
      px: { md: 2, xs: 0 },
      bgcolor: { md: 'background.light', xs: 'transparent' },
      justifyContent: { md: 'flex-end', xs: 'space-around' },
      ...sx,
    }}
    {...props}
  >
    {children}
  </Stack>
);

FingoStack.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.shape(),
};

FingoStack.defaultProps = {
  sx: {},
};

export default FingoStack;
