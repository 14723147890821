import React from 'react';
import { Tooltip, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const IconTooltip = ({ icon, text, children, disabledHover }) => {
  const tooltipContent = (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="center"
      sx={{ py: 0.1 }}
    >
      {icon}
      <Typography
        variant="h2"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {text}
      </Typography>
    </Stack>
  );
  return (
    <Tooltip
      title={tooltipContent}
      placement="bottom-start"
      arrow
      disableHoverListener={disabledHover}
      size="small"
    >
      <span>
        {children}
      </span>
    </Tooltip>
  );
};

IconTooltip.propTypes = {
  icon: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  disabledHover: PropTypes.bool,
};

IconTooltip.defaultProps = {
  disabledHover: false,
};

export default IconTooltip;
