import React, { useEffect, useMemo } from 'react';
import { I18nextProvider } from 'react-i18next';
import PropTypes from 'prop-types';
import i18n from '../../helpers/i18n';
import getCountryFromTld from '../../helpers/country-from-tld';
import { useSelectedCompany } from '../../hooks';

const TranslationProvider = ({ children, userFrontend }) => {
  const selectedCompany = useSelectedCompany();
  const country = useMemo(() => {
    if (userFrontend && selectedCompany?.masterEntity.country.name) {
      return selectedCompany.masterEntity.country.name;
    }
    return getCountryFromTld();
  }, [selectedCompany, userFrontend]);

  useEffect(() => {
    if (country) {
      i18n.changeLanguage(country);
    }
  }, [country]);

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};

TranslationProvider.propTypes = {
  children: PropTypes.element.isRequired,
  userFrontend: PropTypes.bool,
};

TranslationProvider.defaultProps = {
  userFrontend: false,
};

export default TranslationProvider;
