import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  detailFont: {
    fontSize: '15px',
  },
  container: {
    textAlign: 'center',
  },
  leftBlock: {
    textAlign: 'left',
  },
  rightBlock: {
    textAlign: 'right',
  },
  content: {
    padding: '1rem',
  },
  contentRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: '40px',
  },
}));

const Detail = (props) => {
  const classes = useStyles();
  const {
    detailHeaders,
    row } = props;

  return (
    <Grid container className={classes.content} spacing={1}>
      {detailHeaders.map((header) => (
        <Grid container className={classes.contentRow} xs={12}>
          <Grid item xs={6} className={classes.leftBlock}>
            <Typography variant="body2" className={classes.detailFont}> {header.label} </Typography>
          </Grid>
          <Grid item xs={6} className={classes.rightBlock}>
            <Typography variant="body2" className={classes.detailFont}> {row[header.id].component} </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

Detail.propTypes = {
  row: PropTypes.shape.isRequired,
  detailHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Detail;
