import React from 'react';
import PropTypes from 'prop-types';
import {
  Toolbar as MuiToolbar,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1rem',
    padding: 0,
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonsContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  valueLabel: {
    width: 100,
  },
});

const Toolbar = (props) => {
  const classes = useStyles();
  const { topLegend } = props;
  if (topLegend) {
    return (
      <MuiToolbar variant="dense" className={classes.container}>
        <div className={classes.headerContainer}>
          {topLegend}
        </div>
      </MuiToolbar>
    );
  }
  return null;
};

Toolbar.propTypes = {
  topLegend: PropTypes.element,

};

Toolbar.defaultProps = {
  topLegend: null,
};

export default Toolbar;
