import React from 'react';
import { Step, StepConnector, StepIcon, StepLabel, Stepper, Typography, styled } from '@mui/material';
import { Stack } from '@mui/system';
import PropTypes from 'prop-types';

const CustomStepIcon = styled(StepIcon)(() => ({
  color: 'black',
  height: 30,
  width: 30,
  '& .MuiStepIcon-text': {
    fontWeight: 800,
  },
}));

const CustomConnector = styled(StepConnector)(() => ({
  '& .MuiStepConnector-line': {
    borderColor: 'black',
    borderTopWidth: 2,
  },
}));

const TimelineWithIcons = ({ steps }) => (
  <Stepper
    alternativeLabel
    activeStep={-1}
    connector={<CustomConnector />}
    sx={{
      alignItems: 'stretch',
    }}
  >
    {steps.map(({ text, Icon }) => (
      <Step
        key={text}
      >
        <StepLabel
          StepIconComponent={CustomStepIcon}
          sx={{
            height: '100%',
            '& .MuiStepLabel-labelContainer': {
              height: '100%',
            },
            '& .MuiStepLabel-label': {
              height: '100%',
            },
          }}
        >
          <Stack spacing={1} alignItems="center" height="100%" justifyContent="space-between">
            <Typography
              variant="h6"
            >{text}
            </Typography>
            <Icon color="primary" fontSize="large" />
          </Stack>
        </StepLabel>
      </Step>
    ))}
  </Stepper>
);

TimelineWithIcons.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      Icon: PropTypes.elementType.isRequired,
    }).isRequired,
  ).isRequired,
};

export default TimelineWithIcons;
