import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from '@mui/material';

const CustomPagination = ({ page, onChangePage, totalPages }) => {
  const setPage = (e, _page) => onChangePage(e, _page - 1);
  return (
    <Pagination
      boundaryCount={1}
      color="primary"
      count={totalPages}
      page={page + 1}
      shape="rounded"
      siblingCount={1}
      onChange={setPage}
      size="small"
      sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}
    />
  );
};

CustomPagination.propTypes = {
  page: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default CustomPagination;
