import React from 'react';
import { Bolt } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import { InvoiceType } from '../../propTypes';

const FastPaymentTitle = ({ invoice }) => (
  <>
    <Typography variant="caption">
      Portal de proveedores: {invoice.receiver.name}
    </Typography>
    <Typography variant="body2">
      Factura autorizada para pago rápido
    </Typography>
  </>
);

const LightningFastPayment = ({ invoice, ...props }) => (
  <Tooltip title={<FastPaymentTitle invoice={invoice} />}>
    <Bolt color="success" {...props} />
  </Tooltip>
);

FastPaymentTitle.propTypes = {
  invoice: InvoiceType.isRequired,
};

LightningFastPayment.propTypes = {
  invoice: InvoiceType.isRequired,
};

export default LightningFastPayment;
